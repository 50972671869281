import React from 'react';
import css from './LanguageSwitcher.module.scss';
import {
  EN_LOCALE,
  FR_LOCALE,
  SELECTED_BROWSER_LANGUAGE,
  getLocalStorageLocale,
} from '../../util/types';
import { bool } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import { useDispatch } from 'react-redux';
import { updateProfileLocale } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

const LanguageSwitcher = injectIntl(props => {
  const { isAuthenticated } = props;
  const locale = getLocalStorageLocale();

  const dispatch = useDispatch();

  const changeLanguage = e => {
    if (e.target.value === '') return;
    const newLocale = e.target.value;
    if (isAuthenticated) {
      dispatch(updateProfileLocale({ protectedData: { profileLocale: newLocale } })).then(() => {
        window.location.reload();
      });
    } else {
      localStorage.setItem(SELECTED_BROWSER_LANGUAGE, newLocale);
      if (localStorage.getItem(SELECTED_BROWSER_LANGUAGE) === newLocale) {
        window.location.reload();
      } else {
        console.error('Could not set language in local storage.');
      }
    }
  };

  return (
    <div className={css.languageSwitcher}>
      <select onChange={changeLanguage}>
        <option value={FR_LOCALE} selected={locale === FR_LOCALE}>
          FR
        </option>
        <option value={EN_LOCALE} selected={locale === EN_LOCALE}>
          EN
        </option>
      </select>
    </div>
  );
});

LanguageSwitcher.defaultProps = {
  isAuthenticated: false,
};

LanguageSwitcher.propTypes = {
  isAuthenticated: bool.isRequired,
};

export default LanguageSwitcher;
